<template>
  <BaseModal
    :name="fromConfig ? 'Upload-Editor-config' : 'Upload-Editor'"
    :id="fromConfig ? 'Upload-Editor-config' : 'Upload-Editor'"
    :idModal="fromConfig ? 'Upload-Editor-config' : 'Upload-Editor'"
    size="lg"
    title="Selecione sua imagem"
    @shown="restCurent"
  > 
    <img src="../../assets/img/img_null.png" alt="imagem invisível" ref="invisible" v-show="false">
    <b-tabs
      class="mt-4"
      nav-class="TabHeader no-border padding"
      active-nav-item-class="TabHeader--active"
      :no-nav-style="true"
    >
      <b-tab @click="setL = false" :title="`Enviar arquivos`" active>
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          
        >
          <Uploader
            :postAction="returnApi() + 'midia/upload'"
            @update="fetchMidia('', 'selected')"
            @login="isLoading()"
          />
        </b-container>
      </b-tab>

      <b-tab @click="setL = true" :title="`Biblioteca de mídias`">
        <b-container
          fluid
          class="mt-1 wrapper container-user"
          v-show="block"
        >
          <div class="flex-grid">
            <div class="container-left">
              <div class="container-pesquisa">
                <div style="position: relative; width: 100%;">
                  <!-- <h4 class="text-filter">Pesquisar mídia</h4> -->
                  <div class="inputSearch">
                    <img
                      src="@/assets/img/icons/search.svg"
                      class="Pointer searchIcon"
                    />
                    <input
                      type="text"
                      placeholder="Pesquisar itens de mídia"
                      v-model="search_img"
                      @input="debounce"
                      class="input-busca"
                    />
                  </div>
                </div>
              </div>
              <div class="container-img">
                <div class="imgs" v-for="(img, index) in midia" :key="index">
                  <img
                    v-if="
                      (img.type && img.type === 'image/jpeg') ||
                      img.type === 'image/png' ||
                      img.type === 'image/gif' ||
                      img.type === '.webp'
                    "
                    :src="img.url"
                    alt="img"
                    @click="selecionarImg(img)"
                    @error.once="setAltImg"
                  />
                  <img
                    v-else
                    @click="selecionarImg(img)"
                    src="@/assets/icons/pdf_icon.svg"
                    alt="pdf"
                  />
                </div>
              </div>
              <div style="height: 70px; display: relative">
                <div class="paginate-container">
                  <Paginate
                    v-if="midia.length > 0"
                    :totalPages="pagination.totalPages"
                    :activePage="pagination.currentPage"
                    @to-page="toPage"
                    @per-page="perPage"
                    :disabled="true"
                  />
                </div>
              </div>
            </div>
            <div class="container-right">
              <div class="grid-edit">
                <div>
                  <img
                    class="img-style"
                    v-if="
                      (selected_img.type &&
                        selected_img.type === 'image/jpeg') ||
                      selected_img.type === 'image/png' ||
                      selected_img.type === 'image/gif'
                    "
                    :src="selected_img.url"
                    :alt="selected_img.name"
                  />
                  <img
                    v-else
                    class="img-conteudo img-style"
                    src="@/assets/img/icons/img_null.svg"
                    alt="imagem-vazia"
                  />
                </div>
                <div>
                  <div class="img-infos">
                    <div>
                      <label>Nome da mídia</label>
                      <span>{{ selected_img.name }}</span>
                    </div>
                    <div>
                      <label>URL da mídia</label>
                      <span>{{ selected_img.url }}</span>
                    </div>
                    <!-- <div>                      
                      <label>Tipo da mídia</label>
                      <span>{{ selected_img.type }}</span>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-container>
      </b-tab>
    </b-tabs>
    <template v-slot:footer="{ cancel }">
      <BaseButton variant="link-info" class="mr-4" @click="cancel">
        Fechar
      </BaseButton>
      <BaseButton style="background-color: var(--greenn) !important; border-color: var(--greenn) !important;" variant="info" :disabled="!loading" @click="mandarImgSelected">
        Selecionar
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import Uploader from "@/components/Midias/Uploader";
//
import _ from "lodash";
import Paginate from "@/components/Paginate";
//
import MidiaListaService from "@/services/resources/MidiaListaService";
const serviceMidiaLista = MidiaListaService.build();
//
import FileUpload from "vue-upload-component";

export default {
  props: {
    fromConfig: {
      type: Boolean, 
      default: false,
    }
  },
  components: {
    FileUpload,
    Uploader,
    Paginate,
  },
  data() {
    return {
      midia: [],
      block: true,
      setL: false,
      search_img: "",
      selected_img: [],
      loading: false,
      pagination: {
        totalPages: 1,
        currentPage: 1,
        perPage: 12,
      },
    };
  },
  methods: {
    restCurent(){
        this.pagination.currentPage = 1

      },
    isLoading(){
      if(this.setL == false){
        this.block = false
        this.loading = true
      }else{
        if(this.selected_img && this.selected_img.url){
          this.loading = true
        }
      }
      
    },
    toPage(page) {
      this.pagination.currentPage = page;
      this.fetchMidia(this.search_img);
    },
    perPage(qtd) {
      this.pagination.currentPage = 1;
      this.pagination.perPage = qtd;

      this.fetchMidia(this.search_img);
    },
    setAltImg(event) {
      event.target.src = this.$refs.invisible.src;
    },
    returnApi() {
      return process.env.VUE_APP_API_HOST;
    },
    mandarImgSelected() { 
      this.loading = false
      this.block = true
      this.setL = false
      this.$emit("setImg", this.selected_img.url);
      if (this.fromConfig) {
        this.$bvModal.hide("Upload-Editor-config");
      } else {
        this.$bvModal.hide("Upload-Editor");
      }
      this.pagination.currentPage = 1;
    },
    selecionarImg(img) {
      if (img.type === "application/pdf") {
        this.$bvToast.toast("Não é possível selecionar pdf no editor", {
          title: "Atenção",
          variant: "danger",
          autoHideDelay: 5000,
          appendToast: true,
        });
        return;
      }
      this.selected_img = img;
    },
    debounce: _.debounce(function () {
      this.pagination.currentPage = 1;
      this.fetchMidia(this.search_img);
    }, 500),
    async fetchMidia(search = null, selected) {
      this.pagination.totalPages = 1;
      var data = {
        page: this.pagination.currentPage,
      };

      if (this.search_img) {
        data.search = this.search_img;
      }
      await serviceMidiaLista
        .search(data)
        .then((resp) => {
          this.midia = resp.data;
          this.pagination.totalPages = resp.last_page;
          if (selected) {
            this.selected_img = resp.data[0];
          }
        })
       
    },
  },
  created() {
    this.fetchMidia();
  },
  watch:{
    'selected_img'(){
      this.isLoading()
    }
  },
};
</script>

<style lang="scss" scoped>
.img-infos {
  max-width: 196px;
  margin-top: 1rem;
  div {
    border-radius: 10px;
    border: 1px solid rgba(161, 161, 161, 0.192);
    margin: 10px 0 0 0 !important;
    padding: 10px;
  }
  span {
    text-overflow: ellipsis;
    max-width: 196px;
    font-size: 14px;
    color: #4a4c50;
    word-break: break-all;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}

.paginate-container {
  position: absolute;
  transform: translateX(-50%);
  left: 357px;
  bottom: -10px;
}
#name:disabled,
#url_midia:disabled,
#type_midia:disabled {
  color: #a09d9d !important;
}
.img-style {
  width: 100%;
  word-break: break-all;
  height: 145px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid rgba(161, 161, 161, 0.192);
}
.flex-grid {
  display: grid;
  grid-template-columns: 500px 1fr;
  gap: 20px;
}

.inputSearch {
  position: relative;
  width: 100%;
}
.input-busca {
  width: 100%;
  height: 45px !important;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(30, 60, 90, 0.1); */
  border-radius: 10px;
  padding: 20px 30px;
  padding-left: 45px !important;
  border: none;
  font-family: Montserrat;
  font-weight: normal;
  color: #81858e;
  font-size: 14px;
  transition: 0.5s;
  border: 1px solid #ededf0;
}
.input-busca:hover,
.input-busca:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}

.searchIcon {
  position: absolute;
  left: 15px;
  top: 15px;
  width: 14.7px;
  height: 14.7px;
  z-index: 999;
}
.text-filter {
  font-size: 12px;
}
.container-pesquisa {
  display: flex;
  width: 100%;
  // justify-content: flex-end;
  align-items: center;
}
.imgs img {
  width: 100%;
  aspect-ratio: 1;
  cursor: pointer;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid rgba(161, 161, 161, 0.192);
}
.container-img {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow-y: hidden;
  gap: 5px;
}
.newTextDiv {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: var(--fontcolor);
  margin-top: 10px;
  margin-bottom: 10px;
}
.newBtn {
  border: none !important;
  background: var(--greenn) !important;
  color: #fff !important;
  outline: none !important;
  font-weight: 600 !important;
  height: 60px !important;
  padding: 0 42px !important;
  border-radius: 10px !important;
  font-size: 14px !important;

  border: none !important;
  outline: none !important;
  cursor: pointer !important;
  transition: all 0.3s !important;
  line-height: 60px !important;

  /* width: 50% !important; */
  margin: 0 auto !important;
  margin-bottom: 11px !important;
}

.container-left {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: relative;
}
</style>
